* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.quiz__item::after, .includes__thumbnail:before, .includes__item:after, .includes:after, .includes:before, .header:after, .contacts__item a span::after, .footer::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("/fonts/NunitoSans_10pt-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.avatar {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 720px) {
  .avatar {
    flex-direction: column;
  }
}
.avatar__img {
  flex: 0 0 250px;
  min-width: 250px;
  margin: 0 50px 20px 0;
}
@media screen and (max-width: 720px) {
  .avatar__img {
    flex: 0 0 200px;
    max-width: 200px;
    margin: 0 auto 20px auto;
  }
}
.avatar__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.avatar__text {
  font-size: 1.25rem;
  flex: 0 1 auto;
}
@media screen and (max-width: 1070px) {
  .avatar__text {
    font-size: 1rem;
  }
}
.avatar__text p {
  margin-bottom: 20px;
}

.confirmation {
  background: rgba(0, 0, 0, 0.85);
  padding: 40px;
  padding-left: 90px;
  border-radius: 20px;
  transition: all 3s ease-out;
  display: block;
  max-width: 450px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.confirmation button {
  margin: 0;
  margin-right: 20px;
}
.confirmation p {
  line-height: 150%;
  grid-column: 1/-1;
}
.confirmation--whats {
  background-image: url(images/WhatsappLogo.svg);
  background-size: 40px;
  background-position: 40px 40px;
  background-repeat: no-repeat;
}
.confirmation--tel {
  background-image: url(images/PhoneCall.svg);
  background-size: 40px;
  background-position: 40px 40px;
  background-repeat: no-repeat;
}

.footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(../images/footer-bg.webp);
  background-size: cover;
  background-position: right top;
  position: relative;
  padding: 36px 16px 26px 16px;
}
@media screen and (max-width: 570px) {
  .footer {
    background-image: url(../images/tui-vertical.webp);
  }
}
.footer::after {
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}
.footer__icon {
  width: 38px;
  height: 38px;
}
.footer__icon path {
  stroke: #1DAB3D;
}

.contacts__list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-column-gap: 40px;
  grid-template-columns: repeat(auto-fill, 190px);
}
.contacts__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
@media screen and (max-width: 721px) {
  .contacts__item:last-child {
    grid-column: span 2;
  }
}
@media screen and (max-width: 485px) {
  .contacts__item:last-child {
    grid-column: auto;
  }
}
.contacts__item svg {
  margin-bottom: 10px;
}
.contacts__item span {
  color: #C4C4C4;
  font-size: 1.25em;
  display: inline-block;
  margin-bottom: 10px;
}
.contacts__item a span {
  color: white;
  display: inline-block;
  position: relative;
  transition: 0.25s;
}
.contacts__item a span::after {
  background: #C4C4C4;
  height: 1px;
  bottom: 1px;
  transition: 0.25s;
}
.contacts__item a span:hover {
  color: lightgreen;
}
.contacts__item a span:hover::after {
  background: lightgreen;
}

.copyright {
  text-align: center;
  color: #8D928B;
  font-size: 0.9rem;
}
.copyright a {
  position: relative;
}
.copyright a::after {
  content: "";
  width: 100%;
  display: inline-block;
  position: absolute;
  height: 1px;
  left: 0;
  bottom: 1px;
}
.copyright .politic-link, .copyright + .politic-link, .copyright a {
  color: #8D928B;
  transition: 0.25s;
}
.copyright .politic-link::after, .copyright + .politic-link::after, .copyright a::after {
  background-color: #8D928B;
}
.copyright .politic-link:hover, .copyright + .politic-link:hover, .copyright a:hover {
  transition: 0.25s;
  color: lightgreen;
}

.form-example-request {
  display: none;
  background: rgba(0, 0, 0, 0.85);
  padding: 40px;
  max-width: 450px;
  border-radius: 20px;
  transition: all 3s ease-out;
}
.form-example-request.active {
  display: block;
}
.form-example-request .submit-request-form {
  width: 100%;
  margin-bottom: 20px;
}
.form-example-request form {
  margin-top: 20px;
}

.form__title {
  font-size: 20px;
}

.header {
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px 16px 80px 16px;
  overflow: hidden;
  font-family: inherit;
  margin-bottom: 40px;
}
.header:after {
  left: 0;
  top: auto;
  bottom: 0;
  height: 50%;
  background: linear-gradient(0deg, #222D25 0%, transparent 50%);
}
.header__buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .header__buttons {
    gap: 20px;
  }
}
@media screen and (min-width: 571px) {
  .header .icons-button {
    display: none;
  }
}
.header__title {
  text-align: center;
}
.header__studia-name {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.2rem;
}
.header__studia-desc {
  font-size: 2.2rem;
  text-transform: uppercase;
}
@media screen and (max-width: 570px) {
  .header__studia-desc {
    font-size: 1.2rem;
  }
}
.header__studia-accent {
  font-size: 1.1rem;
  text-transform: uppercase;
  display: block;
  margin: 10px 0;
}
@media screen and (max-width: 480px) {
  .header__studia-accent {
    font-size: 0.8rem;
  }
}
.header__action button.red {
  font-size: 1rem;
}
.header__info {
  text-align: center;
}
.header__info span {
  display: block;
}
.header__bg {
  width: 100%;
  height: 100%;
  min-height: 440px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.header__bg picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header__contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 571px) {
  .header__contact .number-icon {
    display: none;
  }
}
.header__contact-number {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.header__contact-number svg {
  width: 28px;
  height: 25px;
  margin-right: 10px;
}
@media screen and (max-width: 570px) {
  .header__contact-number {
    display: none;
  }
}

.logo {
  width: 100%;
  margin: 10px auto 20px auto;
  max-width: 375px;
}

@keyframes pulse {
  from {
    animation-timing-function: ease-in;
    transform: scale(1);
  }
  to {
    animation-timing-function: ease-out;
    transform: scale(1.2);
    transform-origin: center center;
    color: lightgreen;
  }
}
.pulse {
  display: inline-block;
  animation: pulse 1s linear 0s infinite alternate;
}

.includes {
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(../images/tui.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 680px) {
  .includes {
    background-image: url(../images/i-bg.webp);
  }
}
.includes:before {
  background: linear-gradient(180deg, #222D25 0, transparent 20%, transparent 90%, #222D25 100%);
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
}
.includes:after {
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0;
}
.includes__list {
  list-style: none;
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
  grid-column-gap: 40px;
  justify-content: center;
}
.includes__item {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
@media screen and (min-width: 681px) {
  .includes__item {
    height: 300px;
  }
}
.includes__item:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 244px;
  border-radius: 10px;
  background: rgba(11, 29, 15, 0.8);
  z-index: 1;
}
@media screen and (max-width: 740px) {
  .includes__item:after {
    height: 65%;
  }
}
.includes__title {
  position: relative;
  text-align: center;
  font-size: 1.3rem;
  line-height: 120%;
  margin-bottom: 10px;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .includes__title {
    font-size: 1.2rem;
  }
}
.includes__subtitle {
  font-size: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
  color: #B9B9B9;
  margin-bottom: 20px;
}
.includes__thumbnail {
  width: 116px;
  height: 116px;
  border-radius: 150%;
  background: linear-gradient(45deg, #0F932C 0%, #96FF65 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 155px;
}
.includes__thumbnail:before {
  width: 40px;
  height: 40px;
  background-image: url(../images/zoom.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35px;
  left: -50px;
}
.includes__thumbnail--empty:before {
  display: none;
}
.includes__thumbnail--empty picture {
  border-radius: 150%;
  width: 112px;
  height: 112px;
  background: rgb(11, 29, 15);
}
.includes__thumbnail--empty picture img {
  transform: scale(1.3);
}
@media screen and (max-width: 740px) {
  .includes__thumbnail {
    position: relative;
    top: 0;
    left: auto;
    transform: translateX(0);
  }
}
.includes__thumbnail img {
  display: block;
  margin: 0 auto;
  border-radius: 150%;
  width: 112px;
  height: 112px;
}
.includes__pop {
  cursor: pointer;
}
.includes__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.includes__buttons span {
  margin-right: 10px;
}
.includes__buttons span i {
  font-style: normal;
}
@media screen and (max-width: 570px) {
  .includes__buttons span i {
    display: none;
  }
}
.includes__contact {
  margin-bottom: 20px;
}
.includes__contact:nth-child(1) {
  margin-right: 10px;
}
.includes__contact svg path:nth-child(1) {
  stroke: white !important;
}

.popup {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100svh;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease-in-out;
}
.popup.active {
  z-index: 10;
  visibility: visible;
  opacity: 1;
  transition: 0.25s ease-in-out;
}
.popup__img {
  width: auto;
  max-width: 800px;
  max-height: 80vh;
  max-height: 80svh;
  overflow: hidden;
  border-radius: 5px;
}
.popup__close {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
}

.projects {
  position: relative;
}
@media screen and (min-width: 980px) {
  .projects::before, .projects::after {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    width: 100%;
    height: 340px;
    background-image: url(../images/leaf-right.webp);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    z-index: -1;
  }
  .projects::after {
    right: auto;
    left: 0;
    top: 50%;
    background-image: url(../images/leaf-left.webp);
    background-position: left;
  }
}
.projects__list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 500px);
  gap: 60px;
  align-items: start;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .projects__list {
    grid-template-columns: repeat(auto-fit, 400px);
  }
}
@media screen and (max-width: 940px) {
  .projects__list {
    gap: 20px;
    grid-template-columns: repeat(auto-fit, 300px);
  }
}
@media screen and (max-width: 700px) {
  .projects__list {
    grid-template-columns: auto;
  }
}
.projects__item {
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 20px;
}
.projects__image {
  position: relative;
  display: block;
  overflow: hidden;
  height: 300px;
  box-shadow: 0px 20px 90px 0px rgba(19, 191, 67, 0.25);
}
.projects__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
@media screen and (max-width: 700px) {
  .projects__image img {
    height: 100%;
  }
}
.projects__signature {
  padding: 10px;
  font-size: 1rem;
  text-align: center;
}
.projects__more {
  color: #C1C1C1;
  text-align: center;
  margin: 20px auto 0 auto;
}
@media screen and (min-width: 570px) {
  .projects__more {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 480px) {
  .projects__more {
    font-size: 1.2em;
  }
}
.projects__more svg {
  position: relative;
  top: 3px;
}
.projects__more a {
  color: #C1C1C1;
  display: inline-block;
  margin-right: 5px;
  transition: color 0.5s ease;
}
.projects__more a:hover {
  color: white;
  transition: color 0.25s ease;
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup__close {
  width: 30px;
  height: 30px;
}

#quiz {
  padding: 0;
  margin-bottom: 40px;
}

.quiz__slider {
  margin-top: -20px;
}
.quiz__slider .swiper-slide {
  padding-top: 40px;
}
.quiz__item {
  padding: 40px 20px 20px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 457px !important;
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.quiz__item::after {
  background: rgba(0, 0, 0, 0.65);
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.quiz__item--1 {
  background-image: url(../images/quiz-bg-1.webp);
}
.quiz__item--2 {
  background-image: url(../images/quiz-bg-2.webp);
}
.quiz__item--2 .quiz__checkings {
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-gap: 80px;
}
.quiz__item--3 {
  background-image: url(../images/quiz-bg-3.webp);
}
.quiz__item--4 {
  background-image: url(../images/quiz-bg-6.webp);
}
.quiz__item--4 .quiz__wrapper {
  justify-content: center;
}
.quiz__item--5 {
  background-image: url(../images/quiz-bg-6.webp);
}
.quiz__item--5 .quiz__wrapper {
  justify-content: center;
}
.quiz__item--5 .quiz__number {
  position: static;
}
.quiz__number {
  position: absolute;
  top: -60px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 150px;
  background-color: #1DAB3D;
  font-size: 1.2rem;
  margin: 0 auto;
}
.quiz__title {
  width: 100%;
  font-size: 1.25rem;
  text-align: center;
  padding: 20px;
  margin-bottom: 0;
}
.quiz__wrapper {
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.quiz .range {
  width: 100%;
  margin-top: 60px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
}
.quiz .range lable {
  display: none;
}
.quiz .range span {
  font-size: 1.25rem;
}
.quiz .range span em {
  font-size: 2rem;
  font-style: normal;
}
.quiz .range__current {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
}
.quiz .range__input {
  width: 100%;
  flex: 1 1 auto;
  border-radius: 20px;
  height: 3px;
  -webkit-appearance: none;
  background-color: #FDFDFD;
  background-size: 70% 100%;
  background-repeat: no-repeat;
  display: block;
  margin: 40px 20px;
  accent-color: #43D263;
}
.quiz .range__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: grab;
  height: 25px;
  width: 25px;
  border-radius: 150px;
  background-color: #43D263;
  border: none;
}
.quiz .range__input::-moz-range-thumb {
  -webkit-appearance: none;
  cursor: grab;
  height: 25px;
  width: 25px;
  border-radius: 150px;
  background-color: #43D263;
  border: none;
}
.quiz__next {
  position: relative;
  z-index: 2;
}
.quiz__checkings {
  display: grid;
  grid-template-columns: repeat(auto-fit, 270px);
  grid-column-gap: 20px;
  justify-content: center;
}
@media screen and (max-width: 480px) {
  .quiz__checkings {
    grid-template-columns: repeat(auto-fit, 150px);
    padding-left: 20px;
  }
}
@media screen and (max-width: 360px) {
  .quiz__checkings {
    grid-template-columns: 1fr 1fr;
  }
}
.quiz__check {
  display: none;
}
.quiz__check + label {
  font-size: 1.2em;
  position: relative;
  left: 5px;
  padding-left: 36px;
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .quiz__check + label {
    padding-left: 24px;
    font-size: 1rem;
  }
}
.quiz__check + label:before, .quiz__check + label:after {
  content: "";
  position: absolute;
  left: -12px;
  top: -5px;
  width: 32px;
  height: 32px;
  border: 1px solid white;
  display: inline-block;
  background: transparent;
  background-position: center;
  background-size: 0;
  transition: all 0.25s ease-out;
}
@media screen and (max-width: 480px) {
  .quiz__check + label:before, .quiz__check + label:after {
    top: -2px;
    width: 24px;
    height: 24px;
  }
}
.quiz__check + label:last-child {
  grid-column: span 2;
}
.quiz__check:checked + label:before {
  border: none;
  cursor: pointer;
  background-image: url(../images/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  transition: all 0.25s ease-in;
}
.quiz__check:checked + label:after {
  background: linear-gradient(45deg, #025f16 0%, #4ea226 100%);
  z-index: -1;
  border: none;
  transition: all 0.25s;
}
@media screen and (min-width: 481px) {
  .quiz__contacts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
}
.quiz__submit {
  grid-column: span 2;
}
.quiz__submit input[type=submit] {
  margin: 0 0 10px 0;
}
@media screen and (min-width: 481px) {
  .quiz__submit {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: start;
    grid-column-gap: 20px;
  }
}

.politics {
  font-size: 0.8rem;
}

.politic-link {
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: 0.25s;
}
.politic-link:hover {
  cursor: pointer;
  transition: 0.25s;
  color: lightgreen;
}
.politic-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: white;
  width: 100%;
  height: 1px;
}
.politic-link:hover:after {
  transition: 0.25s;
  color: lightgreen;
  background-color: lightgreen;
}

#works {
  padding: 0 40px;
  margin-bottom: 40px;
}

.works {
  position: relative;
}
.works__slider {
  padding-bottom: 35px !important;
}
.works__nav {
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url(../images/arrow.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  top: calc(50% - 5px);
  z-index: 2;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  top: 40%;
}
.works__nav--next {
  right: 0;
}
.works__nav--prev {
  transform: rotate(180deg);
  left: 0;
}
.works__slide {
  width: auto;
  height: 145px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}
.works__slide img {
  width: 110%;
  height: 145px;
  object-fit: cover;
  border-radius: 5px;
}

.swiper-pagination-bullet {
  background: #8b8b8b !important;
}

.swiper-pagination-bullet-active {
  background: #50CE4D !important;
}

.politic {
  z-index: 1;
  visibility: visible;
  display: none;
}
.politic.active {
  display: block;
}
.politic__block {
  width: 100%;
  max-width: 600px;
  background: white;
  height: 80vh;
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
  color: black;
  margin: 0 auto;
}
.politic__header {
  margin-bottom: 20px;
  font-size: 2rem;
  padding-bottom: 10px;
  border-bottom: 1px solid gray;
}
.politic__title {
  text-align: left;
  margin-bottom: 0;
  font-size: 1.2rem;
}
.politic__body {
  height: 90%;
  padding: 10px 0;
  overflow-y: auto;
}
.politic h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: black;
  margin-top: 40px;
}
.politic p {
  margin-bottom: 20px;
  color: #555555;
}
.politic p strong {
  display: block;
  margin-bottom: -20px;
}
.politic a {
  color: blue;
}
.politic ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.politic ul li {
  margin-bottom: 10px;
}

body {
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 1rem;
  background-color: #222D25;
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-size: 1rem;
}

a {
  color: white;
  text-decoration: none;
}

section {
  margin: 20px 0;
  padding: 0 40px;
}
@media screen and (max-width: 480px) {
  section {
    padding: 0 20px;
  }
}
section h2 {
  position: relative;
  font-size: 1rem;
  text-align: center;
  font-family: inherit;
  text-transform: uppercase;
  margin-bottom: 20px;
  z-index: 2;
}
section h2.big {
  font-size: 1.6em;
}

.accent {
  color: #FF5449 !important;
  font-weight: 600;
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

button, input[type=submit] {
  font-family: inherit;
  border: none;
  box-shadow: none;
  background: none;
  color: white;
  padding: 10px 12px;
  border-radius: 5px;
  background: rgba(22, 69, 26, 0.5);
  border: 2px solid transparent;
  border-image: linear-gradient(45deg, #0F932C 0%, #96FF65 100%);
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid #96FF65;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0 auto;
  transition: background 1s ease-out;
}
button.disabled, input[type=submit].disabled {
  background: gray;
  border: none;
  color: darkgray;
  opacity: 0.7;
  pointer-events: none;
}
button:hover, input[type=submit]:hover {
  background: green;
  transition: background 0.25s ease-in;
}
button.red, input[type=submit].red {
  font-size: 1.2em;
  line-height: 1.4;
  position: relative;
  border-radius: 150px;
  background: linear-gradient(333deg, #930F0F 0.78%, #C3164A 78.39%);
  border: none;
  padding: 10px 20px;
  margin: 10px auto;
  z-index: 2;
  max-width: 423px;
}
button.wide, input[type=submit].wide {
  max-width: max-content;
}

.hidden {
  display: none;
}

.icons-button {
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  min-width: 20px;
  margin-right: 8px;
}
.icons-button--whatsapp {
  stroke: 0;
}

.black-block {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  padding: 20px;
}

.title {
  text-align: center;
  text-transform: uppercase;
  font-size: 2rem;
  position: relative;
  margin: 20px auto 60px auto;
}
@media screen and (max-width: 570px) {
  .title {
    font-size: 1.5rem;
  }
}
.title h2 {
  font-size: 2rem;
}
@media screen and (max-width: 570px) {
  .title h2 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
}
.title p {
  font-size: 1.3rem;
  color: #83AC79;
  font-size: 1rem;
}
.title p.accent {
  text-transform: none;
  letter-spacing: 1px;
}
.title span {
  display: block;
}
.title:after {
  content: "";
  display: block;
  width: auto;
  height: 40px;
  background: url(../images/arrow-down.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 0;
}
@media screen and (max-width: 570px) {
  .title:after {
    height: 30px;
  }
}
.title--2::after {
  background: url(../images/listik.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.title--simple {
  margin-bottom: 20px;
}
.title--simple:after {
  display: none;
}

.fast-contact {
  width: 20px;
  height: 20px;
  position: fixed;
  z-index: 10;
  right: 45px;
  bottom: 78px;
  display: none;
}
@media screen and (max-width: 570px) {
  .fast-contact {
    display: block;
  }
}

.input {
  width: 100%;
  height: 46px;
  margin-bottom: 20px;
  background: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  color: white;
  font-size: 1rem;
  outline: none;
  transition: 0.25s;
}
.input::placeholder {
  color: white;
  opacity: 1;
}
.input:hover, .input:focus {
  outline: none;
  transition: 0.25s;
}
.input:focus {
  border-color: lightgreen;
  background: rgba(22, 69, 26, 0.5);
}
.input[name=your-email], .input.input--yandexid {
  display: none;
}
@supports (-webkit-appearance:none){
.confirmation--whats{background-image:url(images/WhatsappLogo.svg)}
.confirmation--tel{background-image:url(images/PhoneCall.svg)}
.includes__thumbnail:before{background-image:url(../images/zoom.svg)}
.quiz__check:checked + label:before{background-image:url(../images/check.svg)}
}

 @media screen and (max-width: 720px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 1070px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 570px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 721px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 485px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 480px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (min-width: 571px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 680px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (min-width: 681px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 740px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (min-width: 980px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 1200px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 940px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 700px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (min-width: 570px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 360px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (min-width: 481px){

@supports (-webkit-appearance:none){

}

}
