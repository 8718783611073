.header
    width: 100%
    height: auto
    position: relative
    padding: 16px 16px 80px 16px
    overflow: hidden
    font-family: inherit
    margin-bottom: 40px
    &:after
        @extend %pseudo
        left: 0
        top: auto
        bottom: 0
        height: 50%
        background: linear-gradient(0deg, $bg 0%, transparent 50%)
    &__buttons
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 40px
        margin-bottom: 20px
        @include max-w(480px)
            gap: 20px
    @include min-w(571px)
        .icons-button
            display: none
    &__title
        text-align: center
    &__studia-name
        font-weight: 400
        font-size: 1rem
        letter-spacing: 0.2rem
    &__studia-desc
        font-size: 2.2rem
        text-transform: uppercase
        @include max-w(570px)
            font-size: 1.2rem
    &__studia-accent
        font-size: 1.1rem
        text-transform: uppercase
        display: block
        margin: 10px 0
        @include max-w(480px)
            font-size: 0.8rem
    &__action
        button.red
            font-size: 1rem
    &__info
        text-align: center
        span
            display: block
    &__bg
        width: 100%
        height: 100%
        min-height: 440px
        overflow: hidden
        position: absolute
        top: 0
        left: 0
        z-index: 0
        picture
            img
                width: 100%
                height: 100%
                object-fit: cover
    &__contact
        display: flex
        flex-direction: column
        justify-content: center
        @include min-w(571px)
            .number-icon
                display: none
    &__contact-number
        margin-bottom: 10px
        display: flex
        align-items: center
        svg
            width: 28px
            height: 25px
            margin-right: 10px
        @include max-w(570px)
            display: none
.logo
    width: 100%
    margin: 10px auto 20px auto
    max-width: 375px

@keyframes pulse
    from
        animation-timing-function: ease-in
        transform: scale(1)
    to
        animation-timing-function: ease-out
        transform: scale(1.2)
        transform-origin: center center
        color: lightgreen

.pulse
    display: inline-block
    animation: pulse 1s linear 0s infinite alternate