.footer
    padding-top: 40px
    padding-bottom: 40px
    background-image: url(../images/footer-bg.webp)
    @include max-w(570px)
        background-image: url(../images/tui-vertical.webp)
    background-size: cover
    background-position: right top
    position: relative
    padding: 36px 16px 26px 16px
    &::after
        @extend %pseudo
        position: absolute
        display: block
        height: 100%
        top: 0
        left: 0
        background-color: rgba(0 0 0 / 0.3)
        z-index: 0
    &__icon
        width: 38px
        height: 38px
        path
            stroke: $main
.contacts
    &__list
        list-style: none
        padding: 0
        margin: 0
        width: 100%
        display: grid
        justify-content: center
        grid-column-gap: 40px
        grid-template-columns: repeat(auto-fill, 190px)
    &__item
        display: flex
        flex-direction: column
        align-items: center
        margin-bottom: 40px
        @include max-w(721px)
            &:last-child
                grid-column: span 2
        @include max-w(485px)
            &:last-child
                grid-column: auto
        svg
            margin-bottom: 10px
        span
            color: #C4C4C4
            font-size: 1.25em
            display: inline-block
            margin-bottom: 10px
        a span
            color: white
            display: inline-block
            position: relative
            transition: 0.25s
            &::after
                @extend %pseudo
                background: #C4C4C4
                height: 1px
                bottom: 1px
                transition: 0.25s
            &:hover
                color: $bright
                &::after
                    background: $bright

.copyright
    text-align: center
    color: #8D928B
    font-size: 0.9rem
    a
        position: relative
        &::after
            content: ""
            width: 100%
            display: inline-block
            position: absolute
            height: 1px
            left: 0
            bottom: 1px
    .politic-link, & + .politic-link, a
        color: #8D928B
        transition: 0.25s
        &::after
            background-color: #8D928B
        &:hover
            transition: 0.25s
            color: $bright