#quiz
    padding: 0
    margin-bottom: 40px
.quiz
    &__slider
        margin-top: -20px
        .swiper-slide
            padding-top: 40px
    &__item
        padding: 40px 20px 20px 20px
        position: relative
        display: flex
        flex-direction: column
        align-items: stretch
        justify-content: center
        height: 457px !important
        max-width: 100%
        background-repeat: no-repeat
        background-position: center
        background-size: cover
        &::after
            @extend %pseudo
            background: rgba(0 0 0 / 0.65)
            height: 100%
            top: 0
            left: 0
            z-index: 1
        &--1
            background-image: url(../images/quiz-bg-1.webp)
        &--2
            background-image: url(../images/quiz-bg-2.webp)
            .quiz__checkings
                grid-auto-flow: column
                grid-template-columns: auto
                grid-gap: 80px
        &--3
            background-image: url(../images/quiz-bg-3.webp)
        &--4
            background-image: url(../images/quiz-bg-6.webp)
            .quiz__wrapper
                justify-content: center
        &--5
            background-image: url(../images/quiz-bg-6.webp)
            .quiz__wrapper
                justify-content: center
            .quiz__number
                position: static
    &__number
        position: absolute
        top: -60px
        z-index: 2
        display: flex
        align-items: center
        justify-content: center
        width: 46px
        height: 46px
        border-radius: 150px
        background-color: $main
        font-size: 1.2rem
        margin: 0 auto
    &__title
        width: 100%
        font-size: 1.25rem
        text-align: center
        padding: 20px
        margin-bottom: 0
    &__wrapper
        width: 100%
        height: 100%
        max-width: 600px
        margin: 0 auto
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between
        position: relative
        z-index: 2
    .range
        width: 100%
        margin-top: 60px
        display: flex
        align-items: center
        position: relative
        margin: 0 auto
        lable
            display: none
        span
            font-size: 1.25rem
            em
                font-size: 2rem
                font-style: normal
        &__current
            position: absolute
            left: 50%
            transform: translateX(-50%)
            top: -20px
        &__input
            width: 100%
            flex: 1 1 auto
            border-radius: 20px
            height: 3px
            -webkit-appearance: none
            background-color: #FDFDFD
            background-size: 70% 100%
            background-repeat: no-repeat
            display: block
            margin: 40px 20px
            accent-color: #43D263
            &::-webkit-slider-thumb
                -webkit-appearance: none
                cursor: grab
                height: 25px
                width: 25px
                border-radius: 150px
                background-color: #43D263
                border: none
            &::-moz-range-thumb
                -webkit-appearance: none
                cursor: grab
                height: 25px
                width: 25px
                border-radius: 150px
                background-color: #43D263
                border: none
    &__next
        position: relative
        z-index: 2
    &__checkings
        display: grid
        grid-template-columns: repeat(auto-fit, 270px)
        grid-column-gap: 20px 
        justify-content: center
        @include max-w(480px)
            grid-template-columns: repeat(auto-fit, 150px)
            padding-left: 20px
        @include max-w(360px)
            grid-template-columns: 1fr 1fr
    &__check
        display: none
        & + label
            font-size: 1.2em
            position: relative
            left: 5px
            padding-left: 36px
            @include max-w(480px)
                padding-left: 24px
                font-size: 1rem
            margin-bottom: 20px
            &:before, &:after
                content: ""
                position: absolute
                left: -12px
                top: -5px
                width: 32px
                height: 32px
                border: 1px solid white
                display: inline-block
                background: transparent
                background-position: center
                background-size: 0
                transition: all 0.25s ease-out
                @include max-w(480px)
                    top: -2px
                    width: 24px
                    height: 24px
            &:last-child
                grid-column: span 2
        &:checked + label
            &:before
                border: none
                cursor: pointer
                background-image: url(../images/check.svg)
                background-repeat: no-repeat
                background-position: center
                background-size: 20px
                transition: all 0.25s ease-in
            &:after
                background: linear-gradient(45deg, #025f16 0%, #4ea226 100%)
                z-index: -1
                border: none
                transition: all 0.25s
    &__contacts
        @include min-w(481px)
            display: grid
            grid-template-columns: 1fr 1fr
            grid-column-gap: 20px
    &__submit
        grid-column: span 2
        input[type="submit"]
            margin: 0 0 10px 0
        @include min-w(481px)
            display: grid
            grid-template-columns: max-content 1fr
            align-items: start
            grid-column-gap: 20px
.politics
    font-size: 0.8rem
    
.politic-link
    display: inline-block
    position: relative
    cursor: pointer
    transition: 0.25s
    &:hover
        cursor: pointer
        transition: 0.25s
        color: $bright
    &::after
        content: ""
        position: absolute
        left: 0
        bottom: -1px
        background-color: white
        width: 100%
        height: 1px
    &:hover:after
        transition: 0.25s
        color: $bright
        background-color: $bright