.confirmation
    background: rgba(0, 0, 0, 0.85)
    padding: 40px
    padding-left: 90px
    border-radius: 20px
    transition: all 3s ease-out
    display: block
    max-width: 450px
    display: grid
    gap: 20px
    grid-template-columns: 1fr 1fr
    button
      margin: 0
      margin-right: 20px 
    p
      line-height: 150%
      grid-column: 1 / -1
    &--whats
      background-image: url(images/WhatsappLogo.svg)
      background-size: 40px
      background-position: 40px 40px
      background-repeat: no-repeat
    &--tel
      background-image: url(images/PhoneCall.svg)
      background-size: 40px
      background-position: 40px 40px
      background-repeat: no-repeat