.form-example-request
    display: none
    background: rgba(0, 0, 0, 0.85)
    padding: 40px
    max-width: 450px
    border-radius: 20px
    transition: all 3s ease-out
    &.active
        display: block
    .submit-request-form
        width: 100%
        margin-bottom: 20px
    form
        margin-top: 20px
        
.form__title
    font-size: 20px