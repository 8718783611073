#works
    padding: 0 40px
    margin-bottom: 40px
.works
    position: relative
    &__slider
        padding-bottom: 35px!important
    &__nav
        display: inline-block
        position: absolute
        width: 30px
        height: 30px
        cursor: pointer
        background-image: url(../images/arrow.webp)
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        top: calc(50% - 5px)
        z-index: 2
        transform: scale(1)
        transition: transform 0.2s ease-in-out
        top: 40%
        &--next
            right: 0
        &--prev
            transform: rotate(180deg)
            left: 0
    &__slide
        width: auto
        height: 145px
        overflow: hidden
        cursor: pointer
        position: relative
        border-radius: 5px
        img
            width: 110%
            height: 145px
            object-fit: cover
            border-radius: 5px

.swiper-pagination-bullet
    background: #8b8b8b !important
.swiper-pagination-bullet-active
    background: #50CE4D !important