*
    padding: 0
    margin: 0
    box-sizing: border-box
%pseudo 
    content: ""
    display: block
    position: absolute
    width: 100%
    height: auto

@mixin gradient
    &:before
        @extend %pseudo
        background: linear-gradient(180deg, $bg 0, transparent 20%, transparent 90%, $bg 100%)
        top: 0
        left: 0
        height: 100%
        z-index: 1
    &:after
        @extend %pseudo
        background: rgba(0 0 0 /0.5)
        top: 0
        left: 0
        height: 100%
        z-index: 0

@mixin max-w($value)
    @media screen and (max-width: $value)
        @content

@mixin min-w($value)
    @media screen and (min-width: $value)
        @content

@font-face
    font-family: 'Nunito Sans'
    src: url('/fonts/NunitoSans_10pt-Regular.ttf') format('truetype')
    font-weight: 400
    font-style: normal
    font-display: swap
$font: 'Nunito Sans', sans-serif