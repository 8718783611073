.politic
    z-index: 1
    visibility: visible
    display: none
    &.active
        display: block
    &__block
        width: 100%
        max-width: 600px
        background: white
        height: 80vh
        border-radius: 5px
        overflow: hidden
        padding: 20px
        color: black
        margin: 0 auto
    &__header
        margin-bottom: 20px
        font-size: 2rem
        padding-bottom: 10px
        border-bottom: 1px solid gray
    &__title
        text-align: left
        margin-bottom: 0
        font-size: 1.2rem
    &__body
        height: 90%
        padding: 10px 0
        overflow-y: auto
    h3
        font-size: 1.2em
        margin-bottom: 10px
        color: black
        margin-top: 40px
    p
        margin-bottom: 20px
        color: #555555
        strong
            display: block
            margin-bottom: -20px
    a
        color: blue
    ul
        list-style: none
        padding: 0
        margin: 0
        li
            margin-bottom: 10px