.popup
    position: fixed
    z-index: 10
    left: 0
    top: 0
    background-color: rgba(0 0 0 / 0.8)
    padding: 20px
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    height: 100vh
    height: 100svh
    z-index: -1
    visibility: hidden
    opacity: 0
    transition: 0.25s ease-in-out
    &.active
        z-index: 10
        visibility: visible
        opacity: 1
        transition: 0.25s ease-in-out
    &__img
        width: auto
        max-width: 800px
        max-height: 80vh
        max-height: 80svh
        overflow: hidden
        border-radius: 5px
    &__close
        display: flex
        align-items: center
        justify-content: center
        border-radius: 150px
        position: absolute
        bottom: 10px
        cursor: pointer