.avatar
    display: flex
    align-items: center
    @include max-w(720px)
        flex-direction: column
    &__img
        flex: 0 0 250px
        min-width: 250px
        margin: 0 50px 20px 0
        @include max-w(720px)
            flex: 0 0 200px
            max-width:200px
            margin: 0 auto 20px auto
        img
            width: 100%
            height: 100%
            object-fit: contain

    &__text
        font-size: 1.25rem
        flex: 0 1 auto
        @include max-w(1070px)
            font-size: 1rem
        p
            margin-bottom: 20px