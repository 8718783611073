.projects
    position: relative
    @include min-w(980px)
        &::before, &::after
            display: block
            content: ""
            position: absolute
            right: 0
            width: 100%
            height: 340px
            background-image: url(../images/leaf-right.webp)
            background-repeat: no-repeat
            background-position: right
            background-size: contain
            z-index: -1
        &::after
            right: auto
            left: 0
            top: 50%
            background-image: url(../images/leaf-left.webp)
            background-position: left
    &__list
        list-style: none
        padding: 0
        display: grid
        grid-template-columns: repeat(auto-fit, 500px)
        gap: 60px
        @include max-w(1200px)
            grid-template-columns: repeat(auto-fit, 400px)
        @include max-w(940px)
            gap: 20px
            grid-template-columns: repeat(auto-fit, 300px)
        align-items: start
        justify-content: center
        @include max-w(700px)
            grid-template-columns: auto
    &__item
        width: 100%
        margin: 0 auto
        border-radius: 5px
        margin-bottom: 20px
    &__image
        position: relative
        display: block
        overflow: hidden
        height: 300px
        box-shadow: 0px 20px 90px 0px rgba(19 191 67 / 0.25)
        img
            width: 100%
            height: 100%
            @include max-w(700px)
                height: 100%
            object-fit: cover
            border-radius: 5px
    &__signature
        padding: 10px
        font-size: 1rem
        text-align: center
    &__more
        color: #C1C1C1
        text-align: center
        margin: 20px auto 0 auto
        @include min-w(570px)
            margin-bottom: 60px
        @include max-w(480px)
            font-size: 1.2em
        svg
            position: relative
            top: 3px
        a
            color: #C1C1C1
            display: inline-block
            margin-right: 5px
            transition: color 0.5s ease
            &:hover
                color: white
                transition: color 0.25s ease

.popup
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    background-color: rgba(0 0 0 / 0.5)
    &__close
        width: 30px
        height: 30px