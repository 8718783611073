
.includes
    padding-top: 40px
    padding-bottom: 40px
    background-image: url(../images/tui.webp)
    @include max-w(680px)
        background-image: url(../images/i-bg.webp)
    background-position: center
    background-repeat: no-repeat
    background-size: cover 
    position: relative
    @include gradient()
    &__list
        list-style: none
        margin: 40px auto
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(325px, 1fr))
        grid-column-gap: 40px
        justify-content: center
    &__item
        max-width: 400px
        margin: 0 auto
        padding: 20px
        position: relative
        margin-bottom: 20px
        @include min-w(681px)
            height: 300px
        &:after
            @extend %pseudo
            top: 0
            left: 0
            width: 100%
            height: 244px
            border-radius: 10px
            background: rgba(11 29 15 / 0.8)
            z-index: 1
        @include max-w(740px)
            &:after
                height: 65%
    &__title
        position: relative
        text-align: center
        font-size: 1.3rem
        line-height: 120%
        margin-bottom: 10px
        z-index: 2
        @include max-w(480px)
            font-size: 1.2rem
    &__subtitle
        font-size: 1rem
        text-align: center
        position: relative
        z-index: 2
        color: #B9B9B9
        margin-bottom: 20px
    &__thumbnail
        width: 116px
        height: 116px
        border-radius: 150%
        background: $border
        display: flex
        align-items: center
        justify-content: center
        margin: 0 auto
        position: absolute
        z-index: 2
        left: 50%
        transform: translateX(-50%)
        top: 155px
        &:before
            @extend %pseudo
            width: 40px
            height: 40px
            background-image: url(../images/zoom.svg)
            background-repeat: no-repeat
            background-position: center
            background-size: 35px
            left: -50px
        &--empty
            &:before
                display: none
            picture
                border-radius: 150%
                width: 112px
                height: 112px
                background: rgba(11 29 15 / 1)
                img
                    transform: scale(1.3)
        @include max-w(740px)
            position: relative
            top: 0
            left: auto
            transform: translateX(0)
        img
            display: block
            margin: 0 auto
            border-radius: 150%
            width: 112px
            height: 112px
    &__pop
        cursor: pointer
    &__buttons
        display: flex
        flex-wrap: wrap
        justify-content: center
        position: relative
        z-index: 1
        span
            margin-right: 10px
            i
                font-style: normal
                @include max-w(570px)
                    display: none
    &__contact
        margin-bottom: 20px
        &:nth-child(1)
            margin-right: 10px
        svg
            path:nth-child(1)
                stroke: white !important