@import "./base/*"
body
    width: 100%
    font-family: $font
    font-size: 1rem
    background-color: $bg
    color: white

h1, h2, h3, h4, h5, h6
    font-weight: 400
    font-size: 1rem

a
    color: white
    text-decoration: none

section
    margin: 20px 0
    padding: 0 40px
    @include max-w(480px)
        padding: 0 20px
    h2
        position: relative
        font-size: 1rem
        text-align: center
        font-family: inherit
        text-transform: uppercase
        margin-bottom: 20px
        z-index: 2
        &.big
            font-size: 1.6em

.accent
    color: $accent !important
    font-weight: 600
.wrapper
    max-width: 1400px
    margin: 0 auto
button, input[type="submit"]
    font-family: inherit
    border: none
    box-shadow: none
    background: none
    color: white
    padding: 10px 12px
    border-radius: 5px
    background: $button-bg
    border: 2px solid transparent
    border-image: $border
    border-radius: 5px
    font-size: 1rem
    border: 1px solid #96FF65
    display: flex
    align-items: center
    justify-content: center
    letter-spacing: 1px
    cursor: pointer
    margin: 0 auto
    transition: background 1s ease-out
    &.disabled
        background: gray
        border: none
        color: darkgray
        opacity: 0.7
        pointer-events: none
    &:hover
        background: green
        transition: background 0.25s ease-in
    &.red
        font-size: 1.2em
        line-height: 1.4
        position: relative
        border-radius: 150px
        background: $red-grad
        border: none
        padding: 10px 20px
        margin: 10px auto
        z-index: 2
        max-width: 423px
    &.wide
        max-width: max-content
.hidden
    display: none

.icons-button
    display: inline-block
    width: 1.3rem
    height: 1.3rem
    min-width: 20px
    margin-right: 8px
    &--whatsapp
        stroke: 0

.black-block
    position: relative
    z-index: 1
    margin: 0 auto
    width: 100%
    max-width: 850px
    display: flex
    flex-direction: column
    align-items: center
    background-color: rgba(0, 0, 0, 0.65)
    border-radius: 5px
    padding: 20px

.title
    text-align: center
    text-transform: uppercase
    font-size: 2rem
    @include max-w(570px)
        font-size: 1.5rem
    position: relative
    margin: 20px auto 60px auto
    h2
        font-size: 2rem
        @include max-w(570px)
            font-size: 1.5rem
            margin-bottom: 5px
    p
        font-size: 1.3rem
        color: #83AC79
        font-size: 1rem
        &.accent
            text-transform: none
            letter-spacing: 1px
    span
        display: block
    &:after
        content: ""
        display: block
        width: auto
        height: 40px
        background: url(../images/arrow-down.webp)
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        margin: 10px 0
        @include max-w(570px)
            height: 30px
    &--2::after
        background: url(../images/listik.webp)
        background-position: center
        background-repeat: no-repeat
        background-size: contain
    &--simple
        margin-bottom: 20px
        &:after
            display: none

.fast-contact
    width: 20px
    height: 20px
    position: fixed
    z-index: 10
    right: 45px
    bottom: 78px
    display: none
    @include max-w(570px)
        display: block

.input
    width: 100%
    height: 46px
    margin-bottom: 20px
    background: transparent
    border: 1px solid white
    border-radius: 5px
    padding: 10px
    color: white
    font-size: 1rem
    outline: none
    transition: 0.25s
    &::placeholder
        color: white
        opacity: 1
    &:hover, &:focus
        outline: none
        transition: 0.25s
    &:focus
        border-color: lightgreen
        background: $button-bg
    &[name="your-email"], &.input--yandexid
        display: none
    